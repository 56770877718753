<template>
  <div>
    <div>

      <div class="misc-inner p-2 p-sm-3 bg-orange">

        <div class="w-100 text-center">
          <b-img
            fluid
            :src="require('@/assets/images/logo/bisevowhite.png')"
            alt="logo img"
            width="150px"
            height="60px"
          />

        </div>

        <div class="w-100 text-center">
          <h3 class="mb-1 page-heading-2">
            You have been unsubscribed from  Notifications.
          </h3>

        </div>

        <div class="w-100 text-center">
          <p
            class="mb-1 page-description-samll"
          >And just so you know, you can always unsubscribe from other emails in your settings. </p>
        </div>

        <br>

        <div class="w-100 text-center img-container">
          <!-- img -->
          <b-img
            fluid
            class="center-img"
            :src="require('@/assets/images/pages/Award-img-1.png')"
            alt="landing page"
          />

        </div>

      </div>
    <!-- /Main Page-->
    </div>
    <b-row>
      <b-col class="col-md-4 col-first" />
      <b-col class="col-md-4 pt-3 col-second">
        <div class="w-100 text-center pb-2 pt-5" />

        <h1 class="big-title text-center w-100">
          <strong>Happy gaming !</strong>
        </h1>

      </b-col>

    </b-row>
    <div class="about" />
    <div>
      <div>
        <b-row>
          <b-col
            lg="10"
            offset-lg="3"
            class="mx-auto"
          >
            <div />
          </b-col>
        </b-row>
      </div>
    </div>

  </div>

</template>

<script>
/* eslint-disable global-require */
import {
  BImg, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import router from '@/router'

export default {
  components: {
    BImg,
    BRow,
    BCol,
  },
  data() {
    return {
      payload: {
        id: '',
        email_type: '',
        email: '',
      },
    }
  },
  created() {
    this.userUnsubscribe()
  },
  methods: {
    ...mapActions('onboarding', ['createUserUnsubscribe']),
    userUnsubscribe() {
      if (router.currentRoute.params.id) {
        this.payload.id = router.currentRoute.params.id
        if (router.currentRoute.query.email_type) {
          this.payload.email_type = router.currentRoute.query.email_type
        }
        if (router.currentRoute.query.email) {
          this.payload.email = router.currentRoute.query.email
        }
        this.createUserUnsubscribe(this.payload)
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.img {
  max-width: 40%;
}
@media only screen and (min-width: 1300px) {
  .img {
  max-width: 50%;
}
}
@media only screen and (max-width: 1300px) {
  .img {
  max-width: 80%;
}
}
@media only screen and (max-width: 700px){
  .col-first {
    display: none;
  }
  .col-third {
    display: none;
  }
  .col-second {
    padding: 0px 25px;
  }
}
@media only screen and (min-width: 1500px) {
  td {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1500px) {
  .big-title {
    margin-top: -60px;
  }
}

</style>
